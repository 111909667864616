export const getISODate = (date) => {
  if (date === null) {
    return date;
  } else {
    const dt = new Date(date);
    // Extract raw date value to ignore timezone and then construct the isoString manually to use UTC and avoid timezone issues (All DB queries need UTC values!)
    const stringifiedDate = [
      String(dt.getFullYear()),
      String(101 + dt.getMonth()).substring(1),
      String(100 + dt.getDate()).substring(1),
    ].join('-');
    const isoDate = `${stringifiedDate}T00:00:00.000Z`;
    return `dt'${isoDate}'`;
  }
}


// Show next ten years array in dropdown
export const getNextTenYears = () => {
  const currentYear = `FY${new Date().getFullYear()} (Current)`;
  let tenYears = [currentYear];
  for (let i = 1; i <= 10; i++) {
    const date = new Date();
    const year = date.setFullYear(date.getFullYear() + i)
    tenYears.push(`FY${new Date(year).getFullYear()}`)
  }
  return tenYears;
}

// Show next ten years array in dropdown
export const getNextTenFiscalYears = () => {
  const currentYear = `FY${new Date().getFullYear()} - Current(Feb 01, ${new Date().getFullYear() - 1} - Jan 31, ${new Date().getFullYear()})`;
  let tenYears = [currentYear];
  for (let i = 1; i <= 10; i++) {
    const date = new Date();
    const year = date.setFullYear(date.getFullYear() + i)
    tenYears.push(`FY${new Date(year).getFullYear()} (Feb 01, ${new Date(year).getFullYear() - 1} - Jan 31, ${new Date(year).getFullYear()})`)
  }
  return tenYears;
}

export const getFiscalYearDates = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth(); // January is 0

  let fiscalYearStart, fiscalYearEnd;

  if (currentMonth >= 1) { // February (1) to December (11)
    fiscalYearStart = `${currentYear}-02-01`;
    fiscalYearEnd = `${currentYear + 1}-01-31`;
  } else { // January (0)
    fiscalYearStart = `${currentYear - 1}-02-01`;
    fiscalYearEnd = `${currentYear}-01-31`;
  }

  return { start: fiscalYearStart, end: fiscalYearEnd };
}
